import {
    Avatar,
    Box,
    Button,
    Container,
    Divider,
    Grid,
    InputAdornment,
    OutlinedInput,
    Tooltip,
    Typography,
    debounce,
} from '@mui/material';
import * as React from 'react';
import Table, { tableClasses } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow, { tableRowClasses } from '@mui/material/TableRow';
import avatar_img from '../../assets/avatar_img.png';
import delete_icon from '../../assets/delete_icon.png';
import view_icon from '../../assets/view_icon.png';
import download_icon from '../../assets/download_icon.png';
import doc_img from '../../assets/doc_img.png';
import PrimaryBtn from '../CustomButton/PrimaryBtn';
import HeaderText from '../Text/HeaderText';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import dev from '../../services/axios-client';
import Pagination from '../Pagination';
import PaginationWrapper from '../Wrapper/PaginationWrapper';
import HeaderWrapper from '../Wrapper/HeaderWrapper';
import { useNavigate } from 'react-router-dom';
import share_icon from '../../assets/share_icon.png';
import FormSubmitDialog from '../Popups/formSubmitDialog';
import WarningDialog from '../Popups/WarningDialog';
import Loader from '../Loader';
import search_light from '../../assets/Search_light.png';
import validationService from '../../services/validationService';
import ShareIcon from '../../assets/share_icon.png';

function createData(
    id,
    name,
    email,
    phone,
    avatar,
    gender,
    influencerStats,
    createdAt,
    activity,
    history,
    action_btns,
) {
    return {
        id,
        name,
        email,
        phone,
        avatar,
        gender,
        influencerStats,
        createdAt,
        activity,
        history,
        action_btns,
    };
}

const headerColumnStyle = {
    fontWeight: 600,
    fontSize: '16px',
    color: '#4B5563',
    backgroundColor: '#F9FAFB',
    fontFamily: 'Plus Jakarta Sans',
};

const bodyColumnStyle = {
    fontFamily: 'Red Hat Display',
    fontWeight: 500,
    fontSize: '16px',
    color: '#74829C',
    padding: '12px 16px',
    border: '1px solid #E9EDF4', // Add border to body cells
};

function ActiveInfluencers() {
    const user = JSON.parse(localStorage.getItem('user'));
    const [rows, setRows] = React.useState([]);
    const [page, setPage] = React.useState({});
    const [search, setSearch] = React.useState('');
    const navigate = useNavigate();
    const [selectedDocument, setSelectedDocument] = React.useState({});
    const [modal, setModal] = React.useState({ open: false });
    const [responseModal, setResponseModal] = React.useState({ open: false });
    const [loading, setLoading] = React.useState(false);

    const handleClose = (flag) => {
        if (flag) {
            handleDeleteDocument(selectedDocument);
        }
        setModal({ ...modal, open: false });
    };
    const handleResponseClose = () => {
        setResponseModal({ open: false });
    };

    const handleOpen = (doc) => {
        setSelectedDocument(doc);
        setModal({
            open: true,
            title: 'Are you sure to delete this Influencer Record?',
            cancelBtn: 'Cancel',
            submitBtn: 'Delete',
        });
    };

    React.useEffect(() => {
        getAllDocuments();
    }, [search]);

    const handleViewDetail = (row) => {
        navigate(`/influencer-profile/${row?.id}`);
    };

    const getAllDocuments = async (page = 1) => {
        setLoading(true);
        await dev
            .get(
                `/api/v1/admin/influencers/pending?page=${page}&per_page=10${
                    search.length ? `&search=${search.toLowerCase()}` : ''
                }`,
                {
                    headers: {
                        token: user.token,
                    },
                },
            )
            .then((response) => {
                if (response.data) {
                    const data = response.data.data.map((data) =>
                        createData(
                            data.id,
                            data.name,
                            data.email,
                            data.phoneNumber,
                            data.avatar,
                            data.gender,
                            data.influencerStats,
                            data.createdAt,
                            <PrimaryBtn
                                style={{ minWidth: { md: '140px' } }}
                                text="Show Activity"
                                onClick={() =>
                                    navigate(
                                        `/influencer-records-activity/${data.id}`,
                                    )
                                }
                            />,
                            <PrimaryBtn
                                style={{ minWidth: { md: '140px' } }}
                                text="Login History"
                                onClick={() =>
                                    navigate(
                                        `/influencer-records-history/${data.id}`,
                                    )
                                }
                            />,
                            <Grid
                                Container
                                direction="row"
                                alignItems="center"
                                minWidth={'60px'}
                            >
                                <Tooltip title="View Details" placement="top">
                                    <img
                                        alt=""
                                        src={share_icon}
                                        style={{
                                            cursor: 'pointer',
                                            marginRight: '20px',
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip title="Delete" placement="top">
                                    <img
                                        alt=""
                                        src={delete_icon}
                                        onClick={() => handleOpen(data)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </Tooltip>
                            </Grid>,
                        ),
                    );
                    setRows(data);
                    setPage(response.data);
                }
            })
            .catch((error) => console.log(error));
        setLoading(false);
    };

    const handlePageChange = async (page) => {
        await getAllDocuments(page);
    };

    const handleDeleteDocument = async (doc) => {
        setLoading(true);
        await dev
            .delete(`/admin/deleteUser`, {
                data: { email: doc.email, id: doc.id },
                headers: {
                    token: user.token,
                },
            })
            .then((response) => {
                setLoading(false);
                if (response.data) {
                    getAllDocuments(page.page);
                    setResponseModal({
                        open: true,
                        title: 'Influencer deleted successfully',
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
                setResponseModal({
                    error: true,
                    open: true,
                    title: 'Failed to delete document',
                });
            });
    };
    const handleSearch = (e) => setSearch(e.target.value);

    return (
        <>
            <FormSubmitDialog
                modal={responseModal}
                onClose={handleResponseClose}
            />
            <WarningDialog modal={modal} onClose={handleClose} />
            <Loader loading={loading} />
            <Grid container spacing={2} sx={{ paddingY: 3 }}>
                <Grid item xs={12} sm={3}>
                    <HeaderText
                        style={{
                            fontFamily: 'IBM Plex Sans',
                            fontSize: '20px',
                            fontWeight: '500',
                            lineHeight: '22px',
                        }}
                        text="Merchants"
                    />
                </Grid>
                <Grid item xs={12} sm={9}>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={'text'}
                        onChange={debounce(handleSearch, 500)}
                        sx={{
                            width: '100%',
                            height: '34px',
                            border: '1px solid #E9EDF4',
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <img
                                    alt=""
                                    src={search_light}
                                    style={{ cursor: 'pointer' }}
                                />
                            </InputAdornment>
                        }
                        placeholder="search"
                    />
                </Grid>
            </Grid>
            <Divider />
            <Loader loading={loading} />
            <TableContainer sx={{ mt: 3 }}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead
                        sx={{
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: '1px solid #E9EDF4',
                            },
                        }}
                    >
                        <TableRow>
                            <TableCell sx={headerColumnStyle}>
                                Picture
                            </TableCell>
                            <TableCell sx={headerColumnStyle}>
                                Full Name
                            </TableCell>
                            <TableCell sx={headerColumnStyle}>Gender</TableCell>
                            <TableCell sx={headerColumnStyle}>
                                Member Since
                            </TableCell>
                            <TableCell sx={headerColumnStyle}>Phone</TableCell>
                            <TableCell sx={headerColumnStyle}>
                                Platform(s)
                            </TableCell>
                            <TableCell
                                sx={{
                                    ...headerColumnStyle,
                                    textAlign: 'center',
                                }}
                            >
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: 'none',
                            },
                        }}
                    >
                        {rows.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell
                                    sx={{
                                        ...bodyColumnStyle,
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Avatar src={row?.avatar} alt={row?.name} />
                                </TableCell>
                                <TableCell
                                    sx={{
                                        ...bodyColumnStyle,
                                        maxWidth: '150px',
                                        fontWeight: '500',
                                    }}
                                >
                                    {row?.name}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        ...bodyColumnStyle,
                                        maxWidth: '110px',
                                    }}
                                >
                                    {row?.gender}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        ...bodyColumnStyle,
                                        maxWidth: '130px',
                                    }}
                                >
                                    {validationService.formatDate(
                                        row?.createdAt,
                                    )}
                                </TableCell>
                                <TableCell sx={bodyColumnStyle}>
                                    {row?.phone}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        ...bodyColumnStyle,
                                        maxWidth: '180px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {row?.influencerStats
                                        ?.map((stat) => stat?.platform)
                                        ?.join(',')}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        ...bodyColumnStyle,
                                        textAlign: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleViewDetail(row)}
                                    >
                                        <Typography
                                            sx={{
                                                display: 'inline',
                                                fontWeight: '500',
                                                marginRight: '4px',
                                            }}
                                        >
                                            View Details
                                        </Typography>
                                        <img
                                            style={{ marginLeft: '5px' }}
                                            src={ShareIcon}
                                            alt=""
                                            width={14}
                                            height={14}
                                        />
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <PaginationWrapper border={'#E9EDF4'} pt={20} pb={210} mt={22}>
                <Pagination
                    currentPage={page?.page}
                    pageSize={page?.per_page}
                    totalUsers={page?.total}
                    onPageChange={handlePageChange}
                />
            </PaginationWrapper>
        </>
    );
}

export default ActiveInfluencers;
